.header {
  --margin: 60px;
  padding: var(--margin) 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__right {
    display: flex;
    align-items: center;
  }
  @include media-breakpoint-down(xl) {
    .desktop-nav {
      display: none;
    }
  }
  @include media-breakpoint-down(md) {
    --margin: 40px;
    &__desktop-auth {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    --margin: 30px;
  }
}

.mobile-nav {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-transparent);
  z-index: 1;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
  cursor: pointer;
  &.active {
    opacity: 1;
    visibility: visible;
    .mobile-nav__container {
      transform: translateX(0);
    }
  }
  &__container {
    background-color: #fff;
    width: 320px;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%);
    transition: transform .25s ease-out;
    cursor: default;
  }
  &__content {
    padding: var(--margin) 20px 0 20px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__link {
    padding: 4px 0;
    font-size: 1.25rem;
    display: block;
    transition: color $trs1;
    position: relative;
    &[data-touch], &[data-hover] {
      color: var(--color-accent);
    }
    &_active {
      color: var(--color-accent);
      pointer-events: none;
      padding-left: 20px;
      &::before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--color-accent);
        position: absolute;
        left: 0;
        top: 13px;
      }
    }
  }
  &__lang-toggle {
    margin-top: 40px;
  }
  &__desktop-auth {
    margin-top: 40px;
  }
  @include media-breakpoint-up(xl) {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    &__container {
      width: 260px;
    }
    &__link {
      font-size: 1.125rem;
    }
    &__content {
      padding: var(--margin) 15px 0 15px;
    }
  }
}

.mobile-nav-toggle {
  z-index: 2;
  margin-left: 50px;
  margin-right: -5px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
  span {
    width: 30px;
    height: 2px;
    background-color: #2784ED;
    position: relative;
    transition: background-color .25s ease-in-out, transform .25s ease-in-out;
    &:last-child {
      margin-top: 8px;
    }
  }
  &.active {
    span {
      background-color: #fff;
      &:first-child {
        transform: translateY(5px) rotate(45deg);
      }
      &:last-child {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
  @include media-breakpoint-up(xl) {
    display: none;
  }
}