.button {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: color $trs1, background-color $trs1;
  font-weight: 500;
  .icon {
    height: 20px;
    width: 20px;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
  &_style-1 {
    font-size: 1.125rem;
    line-height: 46px;
    height: 46px;
    padding: 0 16px;
    border-radius: 5px;
    color: var(--color-button-1);
    background-color: var(--color-button-1-bg);
    &[data-touch], &[data-hover] {
      background-color: var(--color-button-1-hover-bg);
    }
    &[data-click] {
      background-color: var(--color-button-1-click-bg);
    }
  }
  &_style-2 {
    line-height: 68px;
    height: 68px;
    padding: 0 40px;
    border-radius: 10px;
    color: var(--color-button-2);
    background-color: var(--color-button-2-bg);
    font-size: 1.25rem;
    &[data-touch], &[data-hover] {
      background-color: var(--color-button-2-hover-bg);
    }
    &[data-click] {
      background-color: var(--color-button-2-click-bg);
    }
  }
  @include media-breakpoint-down(lg) {
    &_style-2 {
      font-size: 1.125rem;
      line-height: 60px;
      height: 60px;
      padding: 0 30px;
    }
  }
}