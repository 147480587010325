.home-screen {
  padding-top: 250px;
  .shape-1 {
    position: absolute;
    width: 100vw;
    height: calc(100% + 70px);
    top: -50px;
    left: calc(-1 * var(--shape-margin-left) + var(--container-gutter-x));
    border-width: 2px 2px 0 0;
    border-color: var(--color-shape);
    border-radius: 0 40px 0 0;
    border-style: solid;
    transform: translateX(-100%);
  }
  .shape-element {
    left: 350px;
    bottom: 0;
  }
  &__title {
    margin-bottom: 33px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: calc(100% - 0.2em);
      top: 0.05em;
      position: absolute;
      left: calc(-1 * var(--shape-margin-left) - 3px);
      background-color: var(--color-accent);
      border-radius: 4px;
    }
  }
  &__text {
    font-size: 1.125rem;
    color: var(--color-muted);
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: calc(100% - 0.3em);
      top: 0.1em;
      position: absolute;
      left: calc(-1 * var(--shape-margin-left) - 3px);
      background-color: #CFE4FB;
      border-radius: 4px;
    }
  }
  &__content {
    height: 530px;
  }
  &__button {
    margin-top: 40px;
  }
  &__image {
    width: 650px;
    position: absolute;
    right: -80px;
    top: -90px;
    &::before {
      content: '';
      display: block;
      padding-top: 95%;
    }
  }
  @include media-breakpoint-down(xxl) {
    padding-top: 220px;
    &__image {
      top: -60px;
      width: 620px;
    }
  }
  @include media-breakpoint-down(xl) {
    &__image {
      width: 600px;
      right: -120px;
    }
    .shape-element {
      left: 300px;
    }
  }
  @include media-breakpoint-down(lg) {
    padding-bottom: 150px;
    &__image {
      width: 500px;
      right: -200px;
      top: 0;
    }
    &__content {
      height: auto;
    }
    .shape-1 {
      height: calc(100% + 220px);
    }
    .shape-element {
      bottom: -150px;
      left: 250px;
    }
    &__text {
      font-size: 1rem;
    }
  }
  @include media-breakpoint-down(md) {
    padding-top: 200px;
    padding-bottom: 120px;
    &__image {
      right: -160px;
      top: initial;
      width: 300px;
      bottom: -100px;
    }
    .shape-element {
      bottom: -120px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-top: 120px;
    padding-bottom: 0;
    margin-bottom: 60px;
    &__image {
      width: calc(100% - 80px);
      margin: 0 auto 40px auto;
      position: relative;
      bottom: initial;
      right: initial;
    }
    &__title {
      margin-bottom: 25px;
    }
    &__button {
      width: 100%;
      margin-top: 30px;
    }
    .shape-element {
      display: none;
    }
  }
}

.how-it-works {
  margin-bottom: 115px;
  .shape-1 {
    position: absolute;
    top: -50px;
    left: calc(-1 * var(--shape-margin-left) + var(--container-gutter-x) - 2px);
    width: 150px;
    height: 150px;
    border-radius: 0 75px 75px 75px;
    border: 2px solid var(--color-shape);
  }
  .shape-2 {
    position: absolute;
    top: -50px;
    left: calc(-1 * var(--shape-margin-left) + var(--container-gutter-x) - 2px);
    width: 100vw;
    border-top: 2px solid var(--color-shape);
    transform: translateX(-100%);
  }
  .shape-3 {
    position: absolute;
    top: -50px;
    right: calc(-1 * var(--shape-margin-left) + var(--container-gutter-x));
    transform: translateX(100%);
    width: 100vw;
    height: 620px;
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: var(--color-shape);
    border-radius: 40px 0 0 0;
  }
  .shape-4 {
    position: absolute;
    top: 50px;
    left: calc(50% - 1px);
    height: calc(100% + 200px);
    width: 100vw;
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: var(--color-shape);
    border-radius: 40px 0 0 0;
  }
  .shape-element {
    right: var(--container-gutter-x);
    bottom: -100px;
  }
  &__button {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-top: 15px;
    &-inner {
      background-color: #fff;
      padding: 15px;
    }
  }
  @include media-breakpoint-down(lg) {
    .shape-1, .shape-2 {
      display: none;
    }
    .shape-4 {
      top: 80px;
    }
    &__button {
      margin: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 60px;
    &__button-inner {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      .button {
        width: 100%;
      }
    }
    .shape-1, .shape-2, .shape-element {
      display: none;
    }
  }
}

.hw-block {
  position: relative;
  z-index: 2;
  &__image {
    border-radius: 10px;
    background-color: rgba(234, 242, 251, 0.66);
    img {
      padding: 15px;
    }
    &::before {
      content: '';
      display: block;
      padding-top: 73%;
    }
    &_1 {
      img {
        transform: translateX(5%);
      }
    }
    &_2 {
      img {
        transform: translateX(8%);
      }
    }
    &_3 {
      img {
        transform: translateX(3%);
      }
    }
    &_4 {
      img {
        transform: translateX(2%);
      }
    }
  }
  &__index {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    color: #fff;
    background-color: var(--color-accent-2);
    position: absolute;
    left: 30px;
    bottom: -15px;
    font-weight: 500;
  }
  &__title {
    text-align: center;
    margin-top: 33px;
    line-height: 1.625;
  }

  @include media-breakpoint-down(lg) {
    margin-bottom: 50px;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #fff;
    margin-bottom: 35px;
    &__title {
      margin-top: 25px;
    }
  }
}

.section-bookmakers {
  margin-top: 130px;
  margin-bottom: 50px;
  .shape-1 {
    position: absolute;
    top: -60px;
    left: calc(-1 * var(--shape-margin-left) + var(--container-gutter-x) - 2px);
    width: calc(100% - 200px);
    height: calc(100% + 100px);
    border-radius: 40px 0 0 0;
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: var(--color-shape);
  }
  .shape-2 {
    position: absolute;
    top: -150px;
    left: calc(100% - 200px - var(--shape-margin-left) + var(--container-gutter-x) - 2px);
    width: 100vw;
    height: 90px;
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: var(--color-shape);
  }
  .shape-element {
    top: -60px;
    left: calc(100% - 200px - var(--shape-margin-left) + var(--container-gutter-x) - 2px + 20px);
  }
  &__title {
    &:not(:last-child) {
      margin-bottom: 45px;
    }
  }
  &__head-text {
    font-size: 1.125rem;
    color: var(--color-text);
  }
  &__more {
    text-align: center;
    margin-top: 16px;
  }
  &__trigger {
    .icon {
      width: 16px;
      height: 16px;
    }
    span {
      &:nth-child(2) {
        display: none;
      }
    }
    &.is-active {
      .icon {
        transform: rotate(180deg);
      }
      span {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: block;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &__head {
      margin-bottom: 60px;
    }
  }
  @include media-breakpoint-down(xl) {
    .shape-element {
      left: 600px;
    }
  }
  @include media-breakpoint-down(lg) {
    &__more {
      margin-top: 15px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: 0;
    &__more {
      margin-top: 10px;
    }
    &__head-text {
      font-size: 1rem;
    }
    &__trigger {
      width: 100%;
    }
    &__title {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
    .shape-1, .shape-2 {
      display: none;
    }
  }
}

.bk-item {
  margin-bottom: 44px;
  &__image {
    background: rgba(234, 242, 251, 0.84);
    border-radius: 10px;
    height: 110px;
    img {
      padding: 30px;
    }
  }
  &__title {
    text-align: center;
    margin: 20px 0 0 0;
    text-transform: uppercase;
  }
  @include media-breakpoint-down(xl) {
    &__image {
      img {
        padding: 20px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 40px;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
    &__image {
      height: 100px;
      img {
        padding: 10px;
      }
    }
    &__title {
      margin-top: 15px;
    }
  }
}

.section-advantages {
  position: relative;
  margin-bottom: 70px;
  .shape-element {
    left: 430px;
    bottom: -80px;
  }
  .shape-1 {
    content: '';
    display: block;
    height: 1200px;
    width: calc(100% - 80px);
    left: 40px;
    top: 0;
    border-radius: 90px 90px 0 0;
    position: absolute;
    overflow: hidden;
    transform: skewY(7deg);
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(243,248,253,1) 100%);
      transform: skewY(-7deg);
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 50%;
      top: 0;
      background-color: rgba(243,248,253,1);
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__content {
    flex: 0 0 420px;
    position: relative;
  }
  &__item {
    position: absolute;
    width: 100%;
    &.is-active {
      z-index: 2;
    }
    &-title {
      margin-top: 0;
      margin-bottom: 20px;
    }
    &-image {
      width: 340px;
      height: 340px;
      margin: 0 0 50px 10px;
      will-change: transform;
      &_1 img {
        width: 114%;
        height: 114%;
        top: -7%;
        left: -7%;
      }
      &_3 img {
        width: 109%;
        height: 114%;
        top: -7%;
        left: -9%;
      }
      &_4 img {
        width: 109%;
        left: -15%;
      }
      &_5 img {
        width: 110%;
        left: -17%;
        top: 2%;
      }
      &_6 img {
        width: 110%;
        left: -14%;
        top: 2%;
      }
    }
  }
  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px -10px 0;
    flex: 1 1 auto;
    padding-left: 30px;
    position: relative;
  }
  &__card {
    margin: 0 10px 10px 0;
    width: calc(33.33% - 10px);
    text-align: center;
    padding: 15px 20px 20px 20px;
    background-color: #fff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    border: 1px solid transparent;
    transition: border-color $trs1;
    cursor: pointer;
    &.is-active, &[data-touch], &[data-hover] {
      border-color: #2784ED;
    }
    &-title {
      margin: 15px 0 0 0;
    }
    &-image {
      width: 110px;
      height: 110px;
      margin: 0 auto;
      &_1 img {
        transform: translateX(8%);
      }
      &_2 img {
        transform: translateX(13%);
      }
      &_3 img {
        transform: translate(9%, 3%);
      }
      &_4 img {
        transform: translateX(16%) scale(0.9);
      }
      &_5 img {
        transform: translateX(16%);
      }
      &_6 img {
        transform: translateX(13%);
      }
    }
  }
  @include media-breakpoint-down(xl) {
    &__content {
      flex: 0 0 360px;
    }
    &__item-image {
      width: 280px;
      height: 280px;
    }
  }
  @include media-breakpoint-down(lg) {
    .shape-1 {
      width: calc(100% - 40px);
      left: 20px;
    }
    .shape-element {
      display: none;
    }
    &__container {
      display: block;
    }
    &__content {
      margin-bottom: 60px;
    }
    &__cards {
      padding: 0;
    }
  }
  @include media-breakpoint-down(md) {
    &__card {
      width: calc(50% - 10px);
    }
    &__item-image {
      margin-bottom: 40px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 60px;
    .shape-1 {
      width: 100%;
      left: 0;
      border-radius: 30px 30px 0 0;
      transform: skewY(5deg);
      &::after {
        transform: skewY(-5deg);
      }
    }
    &__item-image {
      margin: 0 auto 30px auto;
      width: 240px;
      height: 240px;
    }
    &__item-title {
      margin-bottom: 15px;
    }
    &__card {
      padding: 15px 10px;
    }
    &__card-image {
      width: 80px;
      height: 80px;
    }
    &__content {
      margin-bottom: 40px;
    }
  }
}

.section-income {
  margin-bottom: 30px;
  &__title {
    &:not(:last-child) {
      margin-bottom: 45px;
    }
  }
  &__text {
    font-size: 1rem;
    margin-bottom: 5px;
    color: var(--color-text);
  }
  &__content-title {
    text-align: center;
    margin-bottom: 30px;
  }
  &__chart-content {
    display: flex;
  }
  &__chart-element {
    flex: 0 0 830px;
    &::before {
      content: '';
      display: block;
      padding-top: 48.1%;
    }
    img {
      width: 111%;
      height: 102%;
      top: -2%;
      left: -5%;
    }
  }
  &__chart-info {
    flex: 1 1 auto;
    padding-left: 40px;
    padding-top: 15px;
  }
  &__chart-info-element {
    padding-left: 65px;
    min-height: 60px;
    position: relative;
    font-family: $font2;
    font-weight: 500;
    &:first-child {
      img {
        transform: translateX(-4px);
      }
    }
    &:not(:last-child) {
      margin-bottom: 55px;
    }
    .icon {
      width: 50px;
      height: 50px;
      display: block;
      position: absolute;
      left: 0;
      top: -4px;
    }
    span {
      display: block;
      &:nth-child(2) {
        font-size: 2rem;
        color: #2784ED;
        margin-bottom: 10px;
        line-height: 1.2;
      }
      span {
        display: inline;
        font-size: 0.7em;
      }
    }
  }
  @include media-breakpoint-down(xl) {
    &__chart-content {
      display: block;
    }
    &__chart-info {
      padding: 0;
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  @include media-breakpoint-down(lg) {
    &__chart-info {
      margin: 40px -40px -40px 0;
    }
    &__chart-info-element {
      padding-left: 50px;
      margin: 0 40px 40px 0;
      .icon {
        width: 40px;
        height: 40px;
        top: -5px;
      }
      span {
        &:nth-child(2) {
          font-size: 1.5rem;
          margin-bottom: 5px;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 60px;
    &__title {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &__chart-info {
      display: block;
      margin: 40px 0 0 0;
    }
    &__chart-info-element {
      margin: 0;
      &:not(:last-child){
        margin-bottom: 30px;
      }
    }
  }
}

.section-image {
  &__image {
    .image {
      &::before {
        content: '';
        display: block;
        padding-top: 82%;
      }
    }
  }
  &__container {
    align-items: center;
  }
  @include media-breakpoint-up(lg) {
    &__title {
      margin-left: 24px;
    }
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 70px;
    &__image {
      margin-bottom: 50px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 60px;
    &__image {
      margin-bottom: 40px;
    }
  }
}

.section-compensation {
  &__image {
    img {
      top: -4%;
      left: -36%;
      width: 146%;
      height: 115%;
    }
  }
  &__button {
    margin-top: 40px;
  }
  @include media-breakpoint-down(sm) {
    &__button {
      margin-top: 30px;
      width: 100%;
    }
    &__image {
      img {
        left: -30%;
      }
    }
  }
}

.section-cost {
  &__image {
    img {
      left: 10%;
    }
  }
}

.section-faq {
  margin-bottom: -36px;
  .shape-1 {
    position: absolute;
    top: -80px;
    left: calc(-1 * var(--shape-margin-left) + var(--container-gutter-x) - 2px);
    width: 150px;
    height: 150px;
    border-radius: 0 75px 75px 75px;
    border: 2px solid var(--color-shape);
  }
  .shape-2 {
    position: absolute;
    top: -80px;
    left: calc(-1 * var(--shape-margin-left) + var(--container-gutter-x) - 2px);
    width: 100vw;
    border-top: 2px solid var(--color-shape);
    transform: translateX(-100%);
  }
  .shape-element {
    top: -40px;
    right: 320px;
  }
  @include media-breakpoint-up(xl) {
    &__head {
      margin-bottom: 70px;
    }
    &__container {
      padding: 0 30px;
    }
  }
  @include media-breakpoint-down(lg) {
    .shape-element {
      right: 15px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
    .shape-1, .shape-2, .shape-element {
      display: none;
    }
  }
}

.faq-item {
  margin-bottom: 36px;
  &__container {
    background-color: #EDF4FC;
    border-radius: 10px;
    border: 3px solid #EDF4FC;
    transition: background-color .25s ease-in-out;
    &.is-active {
      background-color: transparent;
    }
  }
  &__trigger {
    display: block;
    text-align: left;
    width: 100%;
    position: relative;
    padding: 20px 37px 18px 87px;
    line-height: 24px;
    font-size: 1.125rem;
    font-family: $font2;
    font-weight: 500;
    span {
      display: block;
      position: absolute;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: #2784ED;
      top: 12px;
      left: 32px;
      transition: background-color .25s ease-in-out;
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 2px;
        transition: opacity .25s ease-in-out;
      }
      &::before {
        width: 2px;
        height: 10px;
      }
      &::after {
        height: 2px;
        width: 10px;
      }
    }
    &.is-active {
      span {
        background-color: #EB5757;
        &::before {
          opacity: 0;
        }
      }
    }
  }
  &__content {
    padding: 10px 40px 30px 90px;
    color: var(--color-muted);
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
    &__trigger {
      padding: 20px 20px 18px 75px;
      font-size: 1rem;
      span {
        left: 20px;
      }
    }
    &__content {
      padding: 0 20px 18px 75px;
    }
  }
}

.section-auth {
  padding-top: 220px;
  .shape-element {
    left: 110px;
    bottom: -100px;
  }
  &__image {
    position: absolute;
    width: 445px;
    display: block;
    height: auto;
    right: -40px;
  }
  &__head {
    padding-left: 200px;
  }
  &__container {
    padding-left: 170px;
  }
  &__form {
    width: 480px;
  }
  @include media-breakpoint-down(xl) {
    &__container {
      padding-left: 0;
    }
    &__head {
      padding-left: 30px;
    }
    .shape-element {
      left: -50px;
    }
  }
  @include media-breakpoint-down(lg) {
    &__form {
      width: 360px;
    }
    &__image {
      width: 320px;
    }
  }
  @include media-breakpoint-down(md) {
    &__image {
      bottom: -130px;
      width: 200px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-top: 160px;
    margin-bottom: 60px;
    &__image {
      bottom: initial;
      top: -25px;
      width: 80px;
      right: 15px;
    }
    .shape-element {
      display: none;
    }
    &__form {
      width: 100%;
    }
    &__head {
      padding-left: 0;
    }
  }
}

