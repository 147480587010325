:root {
  --shape-margin-left: 78px;
  --shape-margin-right: 50px;
  --content-width: #{map-get($container-max-widths, xxl)};
  --container-gutter-x: 15px;
  @include media-breakpoint-down(xxl) {
    --content-width: #{map-get($container-max-widths, xl)};
  }
  @include media-breakpoint-down(xl) {
    --content-width: #{map-get($container-max-widths, lg)};
  }
  @include media-breakpoint-down(lg) {
    --content-width: #{map-get($container-max-widths, md)};
  }
  @include media-breakpoint-down(md) {
    --content-width: #{map-get($container-max-widths, sm)};
  }
}

* {
  @include media-breakpoint-up(md) {
    --bs-gutter-x: 30px !important;
  }
  @include media-breakpoint-down(md) {
    --bs-gutter-x: 15px !important;
  }
}

$field: calc((100vw - #{var(--content-width)} + #{var(--bs-gutter-x)} * 2)/2);

$font1: 'Roboto', sans-serif;
$font2: 'Muller', sans-serif;

$trs1: .15s ease-in-out;
$trs2: .3s ease-in-out;
$trs3: .5s ease-in-out;

$out: cubic-bezier(.23,.74,.35,1);
$in: cubic-bezier(.65,.0,.77,.26);