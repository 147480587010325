.footer {
  flex-shrink: 0;
  margin-top: 150px;
  padding-bottom: 60px;
  color: var(--color-muted);
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__telegram-link {
    transition: color $trs1;
    color: var(--color-text-accent);
    font-size: 1.125rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    &[data-touch], &[data-hover] {
      color: var(--color-accent);
    }
  }
  &__copyright {
    text-align: center;
  }
  @include media-breakpoint-up(xl) {
    &__copyright {
      font-size: 1.125rem;
    }
  }
  @include media-breakpoint-down(xl) {
    &__top {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
    .desktop-nav {
      order: 3;
      width: 100%;
      margin: 40px 0;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 40px 0;
    margin: 0;
    .desktop-nav {
      order: 2;
      margin: 30px 0;
    }
    &__top {
      justify-content: center;
    }
    &__telegram-link {
      font-size: 1rem;
      order: 3;
      margin-bottom: 30px;
    }
  }
}