.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}
.main {
  flex: 1 1 100%;
}

.section {
  position: relative;
  padding: 70px 0 0 0;
  &__head {
    margin-bottom: 55px;
    position: relative;
    z-index: 2;
  }
  &__title {
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50px;
      background-color: #227AEB;
      position: absolute;
      right: calc(100% + 6px);
      bottom: calc(100% + 6px);
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &__head-text {
    color: var(--color-muted);
    font-size: 1.3125rem;
  }
  @include media-breakpoint-down(lg) {
    &__head {
      margin-bottom: 45px;
    }
    &__head-text {
      font-size: 1.125rem;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 50px 0 0 0;
    &__head {
      margin-bottom: 40px;
    }
    &__head-text {
      font-size: 1rem;
    }
    &__title {
      padding-left: 25px;
      &::before {
        width: 14px;
        height: 14px;
        left: 0;
        top: -5px;
      }
    }
  }
}

.container {
  position: relative;
  padding-left: var(--container-gutter-x);
  padding-right: var(--container-gutter-x);
  @include media-breakpoint-up(xxl) {
    max-width: map-get($container-max-widths, xl);
  }
}