.input {
  display: block;
  position: relative;
  &__label {
    display: block;
    margin-bottom: 25px;
    padding-left: 30px;
  }
  &__element {
    color: var(--color-text);
    &:not(textarea) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    display: block;
    font-size: 1.125rem;
    line-height: 24px;
    border: 1px solid #EDF4FC;
    background-color: #EDF4FC;
    padding: 21px 28px;
    width: 100%;
    height: 68px;
    transition: border-color $trs1, background-color $trs1;
    border-radius: 10px;
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--color-text);
      -webkit-box-shadow: 0 0 0 1000px #EDF4FC inset;
    }
    &::placeholder {
      transition: opacity $trs1;
      color: var(--color-muted);
    }
    &:focus {
      background-color:#fff;
      border-color: #2784ED;
      &::placeholder {
        opacity: 0;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &__label {
      margin-bottom: 15px;
    }
    &__element {
      font-size: 1rem;
      line-height: 20px;
      padding: 19px 29px;
      height: 60px;
    }
  }
  @include media-breakpoint-down(lg) {
    &__element {
      padding: 19px;
    }
    &__label {
      margin-bottom: 5px;
      padding-left: 20px;
    }
  }
}

.form {
  &__item {
    &:not(:last-child) {
      margin-bottom: 44px;
    }
  }
  &__submit {
    text-align: center;
  }
  @include media-breakpoint-down(sm) {
    &__item {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &__submit {
      margin-top: 40px;
      .button {
        width: 100%;
      }
    }
  }
}