html {
  font-size: 16px;
}

body {
  font-family: $font1;
  color: var(--color-text);
  font-size: 1rem;
  line-height: 1.625;
  text-rendering: geometricPrecision;
}

button, input, textarea {
  text-rendering: inherit;
  font-weight: inherit;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $font2;
  font-weight: 500;
  color: var(--color-text-accent);
  margin: 1.5rem 0 1rem 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  strong {
    font-weight: inherit;
    color: var(--color-accent);
  }
}

h1, .h1 {
  font-size: 2.8125rem;
  line-height: 1.1;
}
h2, .h2 {
  font-size: 2.25rem;
  line-height: 1.2;
}
h3, .h3 {
  font-size: 1.75rem;
  line-height: 1.3;
}
h4, .h4 {
  font-size: 1.25rem;
  line-height: 1.3;
}
h5, .h5 {
  font-size: 1.125rem;
  line-height: 1.3;
}
h6, .h6 {
  font-size: 1rem;
  line-height: 1.3;
}

@include media-breakpoint-down(sm) {
  h1, .h1 {
    font-size: 1.875rem;
    line-height: 1.2;
  }
  h2, .h2 {
    font-size: 1.625rem;
  }
  h3, .h3 {
    font-size: 1.5rem;
  }
}

p {
  margin: 0 0 1rem 0;
  &:last-child {
    margin-bottom: 0;
  }
}

blockquote {
  display: inline;
  padding: 5px 10px;
  margin: 0 -10px;
  border-radius: 4px;
  background: rgba(34, 122, 235, 0.16);
  color: #2784ED;
  font-weight: 500;
  border: 3px solid #2784ED;
}

mark {
  color: #2784ED;
  background: rgba(34, 122, 235, 0.16);
  font-weight: 500;
  padding: 2px 0;
}

strong, b {
  font-weight: 700;
}