//bootstrap components
$grid-breakpoints: (xs:0, sm:576px, md:768px, lg:1024px, xl:1280px, xxl:1600px);
$container-max-widths: (sm:540px, md:720px, lg:960px, xl:1200px, xxl:1440px);
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/mixins/container";
@import "../../node_modules/bootstrap/scss/mixins/grid";
@import "../../node_modules/bootstrap/scss/mixins/utilities";
@import "../../node_modules/bootstrap/scss/vendor/rfs";
/*========== Variables ==========*/
@import "./components/variables";
//normalize
@import "../../node_modules/normalize.css/normalize";
//bootstrap grid
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/utilities";
$utilities: map-get-multiple($utilities, ("display", "order", "flex", "flex-direction", "flex-grow", "flex-shrink", "flex-wrap", "justify-content", "align-items", "align-content", "align-self"));
@import "../../node_modules/bootstrap/scss/utilities/api";


/*========== Basic ==========*/
@import "./components/basic";

/*========== Typography ==========*/
@import "./components/typography";

/*========== Layout ==========*/
@import "./components/layout";

/*========== Buttons ==========*/
@import 'components/buttons';

/*========== Components ==========*/
@import "./components/components";

/*========== Modal ==========*/
@import "./components/modal";

/*========== Helpers ==========*/
@import 'components/helpers';

/*========== Form ==========*/
@import "./components/form";

/*========== Blocks ==========*/
@import 'components/blocks';

/*========== Header ==========*/
@import 'components/header';

/*========== Footer ==========*/
@import 'components/footer';