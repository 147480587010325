.modal {
  display: none;
  position: fixed;
  z-index: 10000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--color-bg-transparent);
  &.active {
    display: block;
  }
  &__wrapper {
    position: relative;
    z-index: 1;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: var(--container-gutter-x);
    display: flex;
    cursor: pointer;
  }
  &-close {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 10px;
    top: 10px;
    z-index: 2;
    cursor: pointer;
    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 5px;
      width: 30px;
      height: 2px;
      background-color: var(--color-accent);
      transition: opacity $trs1;
    }
    &:before {
      transform: translateY(-50%) rotate(-45deg);
    }
    &:after {
      transform: translateY(-50%) rotate(45deg);
    }
    &[data-touch], &[data-hover] {
      opacity: 0.8;
    }
  }
  &-block {
    position: relative;
    margin: auto;
    padding: 60px;
    cursor: default;
    background-color: #fff;
    border-radius: 10px;
    max-width: 600px;
  }
  @include media-breakpoint-down(sm) {
    &-block {
      padding: 60px 20px 30px 20px;
    }
  }
}