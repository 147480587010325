body {
  min-width: 320px;
  overflow-y: scroll;
  transition: visibility .5s ease-out, opacity .5s ease-out;
  &.loaded {
    opacity: 1 !important;
    visibility: visible !important;
    pointer-events: all !important;
  }
}
* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  min-width: 0px;
  min-height: 0px;
  &:before, &:after {
    box-sizing: border-box;
  }
}
button, input, textarea, select {
  outline: none;
  display: block;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  appearance: none;
  padding: 0;
  border-radius: 0;
  color: inherit;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
textarea {
  resize: none;
}
a, button, label {
  user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
}
ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
table {
  border-collapse: collapse;
}
a {
  text-decoration: none;
  color: inherit;
}

@include media-breakpoint-up(xl) {
  
}